import Typed from "typed.js";
import { useEffect, useRef } from "react";

export default function Content(){
	
	const el = useRef(null);

	useEffect(() => {
		const typed = new Typed(el.current, {
		  strings: ["<i>I am Paul Jacob</i>"], 
		  startDelay: 300,
		  typeSpeed: 100,
		  backSpeed: 100,
		  backDelay: 100
		});

		// Destropying
		return () => {
		  typed.destroy();
		};
	}, []);
  
	return (<div id="content-wrap">
				<div id="content">
					<span className="montserrat-top" ref={el}></span>
				</div>
			</div>);
}