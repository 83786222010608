import React, { useState, useEffect, useRef } from "react";
import Clouds from 'vanta/dist/vanta.clouds.min'
import './App.css';
import Content from './Content';

function App() {
	const [vantaEffect, setVantaEffect] = useState(null);
	const myRef = useRef(null);
	
	useEffect(() => {
		console.log('test');
		if (!vantaEffect) {
		  setVantaEffect(Clouds({
			el: myRef.current,
			mouseControls: true,
			touchControls: true,
			gyroControls: false,
			minHeight: document.body.offsetHeight,
			minWidth: document.body.offsetWidth
		  }))
		}
		return () => {
		  if (vantaEffect) vantaEffect.destroy()
		}
	  }, [vantaEffect])
	  
	return(
		<div>
			<div id="background-outer">
				<div ref={myRef}></div>
			</div>
			<div>
				<Content/>
			</div>
		</div>
	);
}

export default App;
